import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { keccak256, isAddress } from 'ethers/lib/utils';
import contractabi from './contracts/artifacts/contractabi.json';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styled from "styled-components";
import { initWeb3Onboard } from './services'
import Countdown from 'react-countdown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  useAccountCenter,
  useConnectWallet,
  useNotifications,
  useSetChain,
  useWallets,
  useSetLocale
} from '@web3-onboard/react'
import './App.css'
import addressesWL from "./whitelist.json";



let provider

const NFTcontractAddress = "0x390E1939634d361C09d7a7d53eF5a7d5c49b8769";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress sx={{ height: '15px', borderRadius: "30px", background: "#0073ff3b" }} variant="determinate" {...props} />
      </Box>

    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};



function MintPage() {
  const [{ wallet }, connect, disconnect, updateBalances, setWalletModules] =
    useConnectWallet();
  const [notifications, customNotification, updateNotify] = useNotifications();
  const connectedWallets = useWallets();

  const [web3Onboard, setWeb3Onboard] = useState(null);
  const [{ chains, connectedChain, settingChain }, setChain] = useSetChain()
  const [error, setError] = useState('');
  const [errCode, setErrCode] = useState(0);
  const [data, setData] = useState({});
  const [WLb, setWLb] = useState(false);
  const [addresswl, setAddresswl] = useState("");
  const [checkedWL, setCheckedWL] = useState(false);
  const [validWL, setValidWL] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [accountCenterPosition, setAccountCenterPosition] = useState('topRight')
  const [notifyPosition, setNotifyPosition] = useState('bottomRight')
  const [locale, setLocale] = useState('en')
  const [accountCenterSize, setAccountCenterSize] = useState('normal')
  const [accountCenterExpanded, setAccountCenterExpanded] = useState(false)

  const [progress, setProgress] = React.useState(10);


  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard);
  }, [])

  useEffect(() => {
    console.log(notifications)
  }, [notifications])

  useEffect(() => {
    if (!connectedWallets.length) return

    const connectedWalletsLabelArray = connectedWallets.map(
      ({ label }) => label
    )
    window.localStorage.setItem(
      'connectedWallets',
      JSON.stringify(connectedWalletsLabelArray)
    )


  }, [connectedWallets, wallet])

  useEffect(() => {
    if (connectedWallets.length != 0) {
      fetchData();
    }

  }, [connectedWallets])

  useEffect(() => {
    const previouslyConnectedWallets = JSON.parse(
      window.localStorage.getItem('connectedWallets')
    )

    if (previouslyConnectedWallets?.length) {
      async function setWalletFromLocalStorage() {
        await connect({ autoSelect: previouslyConnectedWallets[0], disableModals: true })
      }
      setWalletFromLocalStorage()
    }
  }, [web3Onboard, connect])

  async function checkWLbutton() {
    setValidWL(false);
    console.log(web3Onboard)
    if (isAddress(addresswl)) {
      const { MerkleTree } = require('merkletreejs');
      const leaves = addressesWL.map(x => keccak256(x));
      const tree = new MerkleTree(leaves, keccak256, { sort: true });
      const root = tree.getHexRoot();
      const leaf = keccak256(addresswl);
      const proof = tree.getHexProof(leaf);
      try {
        if (tree.verify(proof, leaf, root))
          setWLb(true);
        else setWLb(false);

        setCheckedWL(true);
        setValidWL(true);
      }
      catch (err) {
        console.log("Invalid address");
      }
    }
    else {
      setCheckedWL(true);
      console.log("Invalid address");
    }
  }


  async function fetchData() {

    const provider = new ethers.providers.Web3Provider(connectedWallets[0].provider, 'any');
    const contract = new ethers.Contract(NFTcontractAddress, contractabi.abi, provider);

    try {
      let cost;

      const totalSupply = await contract.totalSupply();
      const step = await contract.sellingStep();

      if (step == 1) {
        cost = await contract.wlSalePrice();
        document.getElementById("whitelist").classList.add("active");
        document.getElementById("public").classList.remove("active");
        document.getElementById("tokenholders").classList.remove("active");
      }
      if (step == 2) {
        cost = await contract.tokenSalePrice();
        document.getElementById("whitelist").classList.remove("active");
        document.getElementById("public").classList.remove("active");
        document.getElementById("tokenholders").classList.add("active");
      }
      if (step == 3) {
        cost = await contract.publicSalePrice();
        document.getElementById("whitelist").classList.remove("active");
        document.getElementById("public").classList.add("active");
        document.getElementById("tokenholders").classList.remove("active");

      }
      const object = { "cost": String(cost), "totalSupply": String(totalSupply), "step": String(step) }
      console.log(object);
      setData(object);
      setLoading(false);

    }
    catch (err) {
      setError(err.message);
    }

  }
  async function whitelistmint() {

    const provider = new ethers.providers.Web3Provider(connectedWallets[0].provider, 'any');
    const signer = provider.getSigner();
    const { MerkleTree } = require('merkletreejs');
    const contract = new ethers.Contract(NFTcontractAddress, contractabi.abi, signer);
    const leaves = addressesWL.map(x => keccak256(x));
    const tree = new MerkleTree(leaves, keccak256, { sort: true });
    const root = tree.getHexRoot();
    const leaf = keccak256(connectedWallets[0]["accounts"][0]["address"]);
    const proof = tree.getHexProof(leaf);

    try {
      let overrides = {
        from: connectedWallets[0]["accounts"][0]["address"],
        value: String(data.cost * quantity)
      }
      const transaction = await contract.whitelistMint(connectedWallets[0]["accounts"][0]["address"], quantity, proof, overrides);
      await transaction.wait();
      fetchData();
    }
    catch (err) {
      setError(err);
      handleError(err);
    }

  }

  const handleConnect = async () => {
    connect()
      .then(console.log(connectedWallets))
      .catch(e => console.Console.log(e));
  }
  async function mint() {
    const provider = new ethers.providers.Web3Provider(connectedWallets[0].provider, 'any');
    const signer = provider.getSigner();
    const contract = new ethers.Contract(NFTcontractAddress, contractabi.abi, signer);
    try {
      let overrides = {
        from: connectedWallets[0]["accounts"][0]["address"],
        value: String(data.cost * quantity),
      }
      const transaction = await contract.publicSaleMint(connectedWallets[0]["accounts"][0]["address"], quantity, overrides);
      await transaction.wait();
      fetchData();
    }
    catch (err) {
      setError(err);
      handleError(err);
    }
  }
  async function tokenmint() {
    const provider = new ethers.providers.Web3Provider(connectedWallets[0].provider, 'any');
    const signer = provider.getSigner();
    const contract = new ethers.Contract(NFTcontractAddress, contractabi.abi, signer);
    try {
      let overrides = {
        from: connectedWallets[0]["accounts"][0]["address"],
        value: String(data.cost * quantity),
      }
      const transaction = await contract.tokenMint(connectedWallets[0]["accounts"][0]["address"], quantity, overrides);
      await transaction.wait();
      fetchData();
    }
    catch (err) {
      setError(err);
      handleError(err);
    }
  }
  async function handleError(err) {
    if (err.message?.includes("Max per wallet") || err.data?.message?.includes("Max per wallet")) {
      console.log('You are trying to mint more than the allowed amount.');
      toast.error('You are trying to mint more than the allowed amount.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    if (err.message?.includes("You need to own 1 billion tokens") || err.data?.message?.includes("You need to own 1 billion tokens")) {
      console.log('You need to own 1 billion tokens');
      toast.error('You need to own 1 billion tokens.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    if (err.message?.includes("Not whitelisted") || err.data?.message?.includes("Not whitelisted")) {
      console.log('Not whitelisted');
      toast.error('Not whitelisted.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    if (err.message?.includes("user rejected transaction") || err.data?.message?.includes("user rejected transaction")) {
      console.log('User denied the transaction signature.');
      toast.error("Transaction denied", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    if (err.message?.toLowerCase().includes("insufficient") || err.data?.message?.toLowerCase().includes("insufficient")) {
      console.log('Insufficient funds');
      toast.error("Insufficient funds", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

  }
  function valueUp() {
    if (quantity < 1) {
      setQuantity(quantity + 1);
    }
  }
  function valueUpWL() {
    if (quantity < 2) {
      setQuantity(quantity + 1);
    }
  }
  function valueUpToken() {
    if (quantity < 3) {
      setQuantity(quantity + 1);
    }
  }
  function valueDown() {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  }

  const switchNetworkETH = async () => {
    await setChain({ chainId: '0x1' })
  }

  const countdownDate = new Date('2023-04-15T14:00:00Z')
  const Completionist = () => <div className="countdown">
    <span className='live'>LIVE</span>
  </div>;
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return <div className="countdown">
        <span>{days}<h6>days</h6></span><span>{hours}<h6>hours</h6></span><span>{minutes}<h6>minutes</h6></span><span>{seconds}<h6>seconds</h6></span>
      </div>;
    }
  };
  return (
    <main>


      <section className="main">


        <div className='main-content'>
          <div className="imageleft">
            <img src="/assets/preview.gif" alt="" />
          </div>

          <div className="containerr onboard">
            <div className="phases">

              <div className="phase" id="whitelist">
                Whitelist
              </div>
              <div className="phase" id="tokenholders">
                Holders
              </div>
              <div className="phase" id="public">
                Public
              </div>
            </div>
            <h2 className='title'>BORED YETI</h2>
            <Countdown
              date={countdownDate}
              renderer={renderer}
            />
            {<>
              <div>
                {!wallet && (
                  <button
                    className="mintbutton"
                    onClick={
                      handleConnect
                    }
                  >
                    CONNECT
                  </button>
                )}
                {wallet && connectedChain.id == "0x1" && loading && <h2>Loading...</h2>}
                {wallet && connectedChain.id !== "0x1" && <div className="buttonswitch" onClick={switchNetworkETH}><h2>Switch to Ethereum Mainnet</h2><img src="/assets/eth.svg" className='buttonlogo' alt="" /></div>}

                {!loading && wallet && connectedChain.id == "0x1" && (
                  <div className="minting">
                    {data.step != 0 && data.step != null ? (<>
                      <div className="cost">
                        <h2>Price</h2>
                        <h3>{data.cost / 10 ** 18} <span>ETH</span></h3>
                      </div>

                      <div className='progress'>
                        <h3 className="minted">Total minted &nbsp;({data.totalSupply} / 1500) - {Math.round((data.totalSupply * 100 / 1500)) + '%'}</h3>
                        <Box sx={{ width: '100%', height: '60px' }}>
                          <LinearProgressWithLabel value={(data.totalSupply * 100 / 1500)} />
                        </Box>
                      </div>
                    </>
                    ) : (
                      <div>
                        <h3>Sale has not started yet.</h3>
                      </div>
                    )
                    }


                    <br></br>
                    <br></br>

                    {data.step == 1 &&
                      <div>
                        <div className='quantitymint'>
                          <h2>Quantity</h2>
                          <input
                            type="number"
                            id="quantity"
                            min="1"
                            max="2"
                            step="1"
                            value={quantity}
                          />
                          <div className="quantitybuttons">
                            <div className="arrowup" onClick={valueUpWL}></div>
                            <div className="arrowdown" onClick={valueDown}></div>
                          </div>
                        </div>
                        <button className="mintbutton" onClick={whitelistmint}>MINT</button>
                      </div>
                    }
                    {data.step == 2 &&
                      <div className='tokenhold'>
                        <h3>Hold 1 Billion EVCoin tokens to be eligible for Holders Mint</h3>
                        <h3>CA: 0x06fce0dab5b26e503e1c4db48aa165dcaaaa6e11</h3>
                      </div>
                    }
                    {data.step == 2 &&
                      <div>
                        <div className='quantitymint'>
                          <h2>Quantity</h2>
                          <input
                            type="number"
                            id="quantity"
                            min="1"
                            max="3"
                            step="1"
                            value={quantity}
                          />
                          <div className="quantitybuttons">
                            <div className="arrowup" onClick={valueUpToken}></div>
                            <div className="arrowdown" onClick={valueDown}></div>
                          </div>
                        </div>
                        <button className="mintbutton" onClick={tokenmint}>MINT</button>
                      </div>
                    }
                    {data.step == 3 && <div>
                      <div className='quantitymint'>
                        <h2>Quantity</h2>
                        <input
                          type="number"
                          id="quantity"
                          min="1"
                          max="1"
                          step="1"
                          value={quantity}
                        />
                        <div className="quantitybuttons">
                          <div className="arrowup" onClick={valueUp}></div>
                          <div className="arrowdown" onClick={valueDown}></div>
                        </div>
                      </div>
                      <button className="mintbutton" onClick={mint}>MINT</button>
                    </div>}



                  </div>
                )}
              </div>
            </>}

            <div className="wrapper">
              <div className='wrapper-section'>
                <input type="text" required placeholder="Type wallet address" onChange={(e) => setAddresswl(e.target.value)} value={addresswl} />
                <button className='checkbutton' onClick={() => checkWLbutton()}>
                  Check WL
                </button>
              </div>

              {
                addresswl && checkedWL && validWL &&
                <h3 className="wlstatus">{WLb ? "WL Checker - You are Whitelisted!" : "WL Checker - You are not Whitelisted."}</h3>
              }
              {
                !validWL && checkedWL &&
                <h3 className="wlstatus invalid">WL Checker - Invalid wallet address.</h3>
              }

            </div>
          </div>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />



        </div>
      </section>
    </main>
  )
}

export default MintPage
